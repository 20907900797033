import { DropdownMenu } from 'components/UI/DropdownMenu';
import type { MenuItemProps, WidgetMenuProps } from './widget-menu-default';
import styles from './widget-menu-themenwelten.module.scss';

const WidgetMenuThemenwelten = ({ context }: { context: WidgetMenuProps }) => {
  return (
    <div>
      {context.title && <div className={styles.title}>{context.title}</div>}
      <ul className={styles.firstLevelList}>
        {context.model?.menu.map((firstLevel: MenuItemProps) => {
          return (
            <DropdownMenu
              key={`1-${firstLevel.title}`}
              menu={firstLevel}
              className={styles.firstLevel}
              level={1}
              backgroundColor="#5309B3"
              linkClass="first-level"
            />
          );
        })}
      </ul>
    </div>
  );
};

export default WidgetMenuThemenwelten;
